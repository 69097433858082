<template>
  <div class="p-5 text-center bg-light">
    <p>Bestellung erfolgreich. Sie erhalten in wenigen Minuten eine Bestätigung E-Mail</p>
  </div>
</template>
<script>
export default {
  name: "Order ",
  components: {},
  setup() {},
};
</script>

<style scoped></style>
