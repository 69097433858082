<template>
<div><Cart></Cart></div>
</template>

<script>
import Cart from '@/components/Cart'
export default {
  name: "ShopCart",
  components: {
    Cart
  }
}
</script>

<style scoped>

</style>
