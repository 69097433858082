<template>
  <MDBContainer>
    <MDBCard>
      <MDBCardBody>
        <MDBCardTitle>Warenkorb</MDBCardTitle>
        <MDBCardText>
          <div class="mt-5" v-if="cart?.items?.length > 0">
            <MDBTable>
              <thead>
              <tr>
                <th scope="col">Produkt</th>
                <th scope="col">Menge</th>
                <th scope="col">Attribute</th>
                <th scope="col">Punkte</th>
                <th scope="col">Aktion</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(item, i) in cart.items" :key="i">
                <th scope="row">{{ item.product.name }}</th>
                <th scope="row">{{ item.quantity }}</th>
                <th scope="row">
                  <span v-for="(attr,ii) in item.attribute_options" :key="ii">{{ attr.name }} :
                    <span v-for="(opt, oi) in attr.options" :key="oi">{{ opt.translated_name }}</span><br>
                  </span>
                </th>
                <th scope="row">{{ item.product.points }}</th>
                <th scope="row">
                  <MDBBtn outline="danger" rounded @click.prevent="deleteItem(item.token)">Löschen</MDBBtn>
                </th>
              </tr>
              <tr>
                <th scope="row" colspan="10">
                  <p>Punkte insgesamt: <strong>{{ cart.total }}</strong></p>
                </th>
              </tr>
              </tbody>
            </MDBTable>
          </div>
          <div v-else>
            Warenkorb ist leer.
          </div>
        </MDBCardText>

      </MDBCardBody>
      <MDBCardFooter class="text-muted" v-if="cart?.items?.length > 0">
        <MDBBtn color="primary" @click="createOrder(cart.token)">Bestellen</MDBBtn>
      </MDBCardFooter>
    </MDBCard>
  </MDBContainer>
</template>

<script>
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import { computed } from 'vue';
import { MDBContainer,MDBCard,MDBCardBody, MDBCardTitle, MDBCardText, MDBBtn, MDBCardFooter, MDBTable } from "mdb-vue-ui-kit";

export default {
  name: "Cart",
  components: {
    MDBContainer,
    MDBCard,
    MDBCardBody,
    MDBCardTitle,
    MDBCardText,
    MDBBtn,
    MDBCardFooter,
    MDBTable
  },

  setup() {
    const store = useStore()
    const route = useRoute()

    store.dispatch('me')

    const deleteItem = async (token) => {
      await store.dispatch('deleteItem', token)
    }

    const createOrder = async (token) => {
      const orderData = {
        token: token
      }

      await store.dispatch('createOrder', orderData)
    }

    return {
      route,
      createOrder,
      cart: computed(() => store.getters.getCart),
      deleteItem
    }
  }
}
</script>

<style scoped>

</style>
