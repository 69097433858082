<template>
<UserOrder></UserOrder>
</template>

<script>
import UserOrder from "@/components/Order";
export default {
  name: "Shop",
  components: {
    UserOrder,
  }
}
</script>

<style scoped>

</style>
